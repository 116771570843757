import React from "react"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import blog from "../images/blog.jpg"
import blogStyles from "./blog.module.scss"
import { Link } from 'gatsby';

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  `)

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className={blogStyles.container}>
        <SEO
          title="Blog"
          description="Información de valor y actualizada para cualquier entusiasta del mundo digital"
          image={`https://www.ppdc.mx${blog}`}
          url="/blog"
        />
        <h1 className={blogStyles.title}>Blog</h1>
        <ol className={blogStyles.posts}>
          {data.allContentfulBlogPost.edges.map(edge => {
            return (
              <li className={blogStyles.post}>
                <Link
                  title={`blog-entry-${edge.node.slug}`}
                  fade
                  to={`/blog/${edge.node.slug}`}
                >
                  <h2>{edge.node.title}</h2>
                  <p>{edge.node.publishedDate}</p>
                </Link>
              </li>
            )
          })}
        </ol>
      </div>
    </div>
  )
}

export default BlogPage
